import React from "react";
import "../assets/css/phoneFooter.css";
import phoneIconYellow from "../assets/img/phoneIconYellow.svg";
import phoneIconBlack from "../assets/img/phoneIcon.svg";

const PhoneFooter = (data) => {
  const url = window.location.pathname;

  let phone = data.data.phoneNumber_rm
  let numberIcon = data.data.phoneNumberIcon_rm
  if (url === "/lima"){
      phone = data.data.phoneNumber_lima
      numberIcon = data.data.phoneNumberIcon_lima
  }
  return (
    <div className="floating-button" type="button">
      <div className="button-title">
        <img src={phoneIconYellow} alt="Phone" className="phoneIconWeb" />
        <img src={phoneIconBlack} alt="Phone" className="phoneIconMobile" />
        <p>Llamanos</p>
      </div>
      <a href={`tel:${phone}`}>
        <b>{numberIcon}</b>
      </a>
    </div>
  );
};

export default PhoneFooter;


/*

            {window.innerWidth <= 1001 ? (
              <img
                src={promocionAlarma1Mobile}
                alt={text}
                style={{ width: "100%", height: "100%" }}
              />
            ) : (
              <img
                src={promocionAlarma1}
                alt={text}
                className="d-block w-100"
              />
            )}

*/